<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2021-03-12 16:46:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\appoint\appoint.vue
-->
<template>
  <div class="docmain_page">
    <div class="page_header">
      <img class="header_img" :src="seleteDocDic.docImage?seleteDocDic.docImage:'https://lanzhoutech.oss-cn-shanghai.aliyuncs.com/naokeWebImages/h5/public_doctor_info.png'">
      <div class="header_info">
        <div class="info_docName">
          {{ seleteDocDic.docName }} {{ seleteDocDic.docTitle }}
        </div>
        <div class="info_deptName">
          {{ seleteDocDic.deptName }}
        </div>
      </div>
    </div>
    <div class="page_body">
      <div class="body_title">
        <a class="title_line">|</a>排班信息
      </div>
      <div class="body_cont">
        <div v-for="(item, index) in dateList" :key="index">
          <div class="cont_one">
            {{ item.resourceWeek }} <br>{{ String(item.resourceDate).substring(5, 10) }}
          </div>
          <div class="cont_two">
            <div v-if="index == 0">
              上午
            </div>
            <div v-else>
              <div v-if="item.am=='1'" :class="item.amType>0?'two_back':'two_back_1'" @click="seleteDateAction(item, '1')">
                {{ item.amType>0?'预约':'约满' }}
              </div>
              <div v-else />
            </div>
          </div>
          <div class="cont_two">
            <div v-if="index == 0">
              下午
            </div>
            <div v-else>
              <div v-if="item.pm=='2'" :class="item.pmType>0?'two_back':'two_back_1'" @click="seleteDateAction(item, '2')">
                {{ item.pmType>0?'预约':'约满' }}
              </div>
              <div v-else />
            </div>
          </div>
        </div>
      </div>
      <div class="body_des">
        (排班时间以医院时间发布为准)
      </div>
    </div>
    <div class="page_bottom">
      <div class="bottom_title">
        <a class="title_line">|</a>擅长
      </div>
      <div class="bottom_des">
        {{ seleteDocDic.docGoodAt }}
      </div>
    </div>
    <div class="page_bottom">
      <div class="bottom_title">
        <a class="title_line">|</a>简介
      </div>
      <div class="bottom_des">
        {{ seleteDocDic.docDesc }}
      </div>
    </div>
    <van-popup v-model="seleteDateShow" position="bottom" :style="{ height: '60%' }">
      <div class="popup_page">
        <div class="popup_page_header">
          <van-icon class="popup_page_header_cross" name="cross" size="22" @click="seleteDateShow = false" />
          <div class="popup_page_header_title">
            请选择时间段                    
          </div>
        </div>
        <div class="popup_page_cont">
          <div class="popup_page_cont_fee">
            诊查费 <a class="popup_page_cont_fee_color">¥{{ seleteDocDic.fee }}</a>
          </div>
          <div class="popup_page_time">
            <div class="popup_page_time_item">
              <div v-for="(item, index) in seleteDateDic.timeRangeList" :key="index" class="popup_page_time_item_1">
                <div v-if="index%2==0" :class="item.remainingCount>0 ? 'popup_page_time_cell' : 'popup_page_time_cell_Full' " @click="getGjcRegisterAction(item)"> 
                  {{ item.timeBegin }}-{{ item.timeEnd }}   
                  <div v-if="item.remainingCount>0" class="popup_page_time_cell_color">
                    剩余{{ item.remainingCount }}
                  </div>
                  <div v-else class="popup_page_time_cell_color">
                    已约满
                  </div>
                </div>
              </div>
            </div>
            <div class="popup_page_time_item">
              <div v-for="(item, index) in seleteDateDic.timeRangeList" :key="index" class="popup_page_time_item_1">
                <div v-if="index%2!=0" :class="item.remainingCount>0 ? 'popup_page_time_cell_1' : 'popup_page_time_cell_Full' " @click="getGjcRegisterAction(item)">
                  {{ item.timeBegin }}-{{ item.timeEnd }}  
                  <div v-if="item.remainingCount>0" class="popup_page_time_cell_color">
                    剩余{{ item.remainingCount }}
                  </div>
                  <div v-else class="popup_page_time_cell_color">
                    已约满
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'

export default {
    data(){
        return{
            dateList:[], //日期列表
            currentData:[], //数据列表
            deptCode:'',
            docCode:'',
            currentDate:'',
            seleteDocDic:{},
            seleteDateDic:{}, //选择医生上下午时间
            seleteDateShow:false  //选择时间段显示
        }
    },
    mounted() {
        this.getQueryRegisterData()
    },
    methods:{
        //获取号源列表
        getQueryRegisterData(type){
            const params = {
                hospCode: '',
                docCode: this.$route.query.docCode,
                currentDate:this.currentDate,
                deptCode: this.$route.query.deptCode
            }
            gjcModel.getQueryRegisterData(params).then((result) => {
                // console.log('医生主页号源列表', result)
                this.dateList = []
                const dateList = result.data.dateList || []
                dateList.forEach(date => {
                    date.am=''
                    date.amType=0
                    date.pm=''
                    date.pmType=0
                    const dutyList = date.dutyList || []
                    dutyList.forEach(duty => {
                        if(duty.dutyType=='1'){
                            date.am='1'
                            date.amType=duty.registerLimit-duty.registerCount
                        }else if(duty.dutyType=='2'){
                            date.pm='2'
                            date.pmType = duty.registerLimit-duty.registerCount
                        }
                    })
                    this.dateList.push(date)
                })
                this.currentData = result.data.currentData
                this.seleteDocDic = this.currentData[0]
                if(type){ //上午,下午
                    const dutyDataList = this.seleteDocDic.dutyDataList || []
                    dutyDataList.forEach(duty => {
                        if(duty.dutyTypeCode==type){
                            this.seleteDateDic = duty
                            this.seleteDateShow = true
                        }else{
                            console.log('不存在---', type)
                        }
                    })
                }
            })
        },
        //选择医生时间
        seleteDateAction(item, type){
          console.log('item',item)
            // if(item.amType>0 && type=='1'){ //上午
            //     // console.log('选择医生时间', item)
            //     this.currentDate = item.resourceDate
            //     this.getQueryRegisterData(type)
            // }else if(item.pmType>0 && type=='2'){ //下午
            //     // console.log('选择医生时间', item)
            //     this.currentDate = item.resourceDate
            //     this.getQueryRegisterData(type)
            // }
            if( type=='1'){ //上午
                // console.log('选择医生时间', item)
                this.currentDate = item.resourceDate
                this.getQueryRegisterData(type)
            }else if( type=='2'){ //下午
                // console.log('选择医生时间', item)
                this.currentDate = item.resourceDate
                this.getQueryRegisterData(type)
            }
        },
        //选择医生时间段
        getGjcRegisterAction(item){
            console.log('选择医生时间段----', item)
            if(item.remainingCount == 0){
              return
            }
            this.$router.push({
                path: '/gjcRegister',
                query: {
                    seleteTimeDic:JSON.stringify(item),
                    seleteDateDic:JSON.stringify(this.seleteDateDic),
                    seleteDocDic:JSON.stringify(this.seleteDocDic)
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>
    .docmain_page{
        .page_header{
            display: flex;
            justify-items: center;
            margin: 20px;
            .header_img{
                width: 120px;
                height: 120px;
            }
            .header_info{ 
                margin-left: 20px;
                .info_docName{
                    height: 70px;
                    font-size: 32px;
                    line-height: 70px;
                }
                .info_deptName{
                    height: 50px;
                    font-size: 24px;
                }
            }
        }
        .page_body{
            border-top: 20px solid #e5e5e5;
            .body_title{
                height: 90px;
                font-size: 32px;
                line-height: 90px;
                padding-left: 20px;
                border-bottom: 2px solid #e5e5e5;
                .title_line{
                    margin-left: 24px;
                    margin-right: 24px;
                    background-color: #1e80ff;
                    color: #1e80ff;
                    width: 4px;
                    height: 26px;
                }
            }
            .body_cont{ 
                display: flex;
                overflow-x: auto; /* 启用垂直滚动 */

                .cont_one{
                    padding: 20px 10px;
                    width: 100px;
                    height: 80px;
                    text-align: center;
                    font-size: 28px;
                    color: #333;
                }
                .cont_two{
                    border: #e5e5e5 solid 1px;
                    padding: 20px 10px;
                    width: 100px;
                    height: 60px;
                    text-align: center;
                    font-size: 28px;
                    line-height: 60px;
                    color: #333;
                    .two_back{
                        width: 100%;
                        height: 100%;
                        background-color: #e5e5e5; 
                        color: #1e80ff;
                    }
                    .two_back_1{
                        width: 100%;
                        height: 100%;
                        background-color: #e5e5e5; 
                        color: #666;
                    }
                }
            }
            .body_des{
                padding: 20px;
                text-align: right;
                font-size: 24px;
                color: #999;
            }
        }
    }
    .page_bottom{
        border-top: 20px solid #e5e5e5;
        .bottom_title{
            height: 90px;
            font-size: 32px;
            line-height: 90px;
            padding-left: 20px;
            border-bottom: 2px solid #e5e5e5;
            .title_line{
                margin-left: 24px;
                margin-right: 24px;
                background-color: #1e80ff;
                color: #1e80ff;
                width: 4px;
                height: 26px;
            }
        }
        .bottom_des{
            margin: 10px 20px;
            background-color: #e5e5e5;
            border-radius: 5px;
            padding: 20px;
            font-size: 30px;
        }
    }
    .popup_page{
        background-color: #fff;
    }
    .popup_page_header{
        display: flex;
    }
    .popup_page_header_title{
        width: 100%;
        margin: 20px 0;
        // padding: 20px 100px;
        font-size: 32px;
        color: #333;
        text-align: center;
        line-height: 40px;
    }
    .popup_page_header_cross{
        position: absolute;
        margin: 20px 0;
        right: 20px;
    }
    .popup_page_cont{
        background-color: #fff;
    }
    .popup_page_cont_fee{
        width: 100%;
        font-size: 30px;
        color: #333;
        text-align: center;
        line-height: 60px;
    }
    .popup_page_cont_fee_color{
        color: #f80404;
    }
    .popup_page_time{
        display: flex;
        justify-content: space-between;
    }
    .popup_page_time_item{
        width: 50%;
    }
    .popup_page_time_cell{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin: 20px 10px 20px 20px;
        background: rgba(30,141,255,0.08);
        border-radius: 12px;
        padding: 10px 20px 10px 20px;
        font-size: 26px;
        line-height: 50px;
        color: #1e8dff ;
        font-weight: 500;
      font-family: PingFangSC-Regular;
    }
    .popup_page_time_cell_1{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin: 20px 20px 20px 10px;
        background: rgba(30,141,255,0.08);
        border-radius: 12px;
        padding: 10px 20px 10px 20px;
        font-size: 26px;
        line-height: 50px;
        color: #1e8dff ;
        font-weight: 500;
    }
    .popup_page_time_cell_Full{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin: 20px 20px 20px 10px;
        background: #eef1f5;
        border-radius: 12px;
        padding: 10px 20px 10px 20px;
        font-size: 26px;
        line-height: 50px;
        color: #9c9fad ;
        font-weight: 500;
    }
    .popup_page_time_cell_color{
        color: #6a6d78;
        font-size: 26px;
    }
</style>
